import C from "../main/TopNav.module.css";

import { Link } from "@tanstack/react-router";
import { useStore } from "jotai/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { VncScreen, type VncScreenHandle } from "react-vnc";
import invariant from "tiny-invariant";
import { Toolbar } from "../../components/Toolbar.tsx";
import { ToolbarText } from "../../components/ToolbarText.tsx";
import { WToolButton } from "../../components/button/WToolButton.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { currentApiParamsAtom } from "../api/apiClient.store.ts";
import { type ApiParameters, getWebsocketUrl } from "../api/createApiClient.ts";
import { getResourceById } from "../api/resourceTypeException.ts";
import { assignedVmsQueryAtom } from "./joinAssignedQuery.ts";

interface VncWsUrl extends ApiParameters {
    vmId: string;
    location: string;
}

function getVncWsUrl({ vmId, location, apikey, apiPrefix }: VncWsUrl) {
    invariant(apikey, "API key must be set");
    const search = new URLSearchParams({
        apikey,
        token: `${apikey}:${vmId}`,
    });
    return `${getWebsocketUrl(apiPrefix)}/${location}/vm-console?${search}`;
}

function getVncPassword(vmId: string) {
    return vmId.split("").reverse().join("");
}

/**
 * Documentation:
 * - https://github.com/roerohan/react-vnc
 *      - Source code: https://github.com/roerohan/react-vnc/blob/main/src/lib/VncScreen.tsx
 * - https://github.com/novnc/noVNC/blob/master/docs/API.md
 */
export function Vnc({ vmId, location }: { vmId: string; location: string }) {
    const vms = useSuspenseQueryAtom(assignedVmsQueryAtom(location));
    const displayRef = useRef<VncScreenHandle>(null);
    const store = useStore();
    const apiParams = useMemo(() => store.get(currentApiParamsAtom), [store]);
    const [isConnected, setIsConnected] = useState(false);

    const vm = getResourceById(vms, vmId, "virtual_machine");
    const { name } = vm;

    useEffect(() => {
        document.title = `${name} - Terminal`;
    }, [vmId, name]);

    // TODO: Resize remote screen to fit

    return (
        <div className="flex flex-col h-full">
            <div>
                <div className="flex justify-between">
                    <div className="flex-1">
                        <Toolbar>
                            <ToolbarText icon="jp-monitor-icon size-0.875rem color-primary">{`${name} - Terminal`}</ToolbarText>

                            <WToolButton
                                icon="jp-media-loop-icon"
                                label="Send Ctrl+Alt+Del"
                                action={() => displayRef.current?.sendCtrlAltDel()}
                            />
                            <WToolButton
                                icon="jp-icon-refresh"
                                label="(Re)Connect"
                                action={() => displayRef.current?.connect()}
                                isDisabled={isConnected}
                            />
                            {import.meta.env.DEV && (
                                // Allow disconnecting in dev to test loading state

                                <WToolButton
                                    icon="i-lucide:bug"
                                    label="Disconnect"
                                    action={() => displayRef.current?.disconnect()}
                                    isDisabled={!isConnected}
                                />
                            )}

                            {/* <Spacer /> */}
                        </Toolbar>
                    </div>

                    <div className="p-2">
                        <ToolbarText>
                            <Link to="/" aria-label="Home" className={`bg-icon ${C.logo}`} />
                        </ToolbarText>
                    </div>
                </div>
            </div>

            <div
                // Extra wrapper component so we can position the loading indicator on top
                className="flex-grow flex flex-col relative"
            >
                <VncScreen
                    ref={displayRef}
                    // Style
                    // NB: This applies just to the wrapper <div>, not the screen canvas...
                    className="flex-grow bg-#000"
                    // NB: The loadingUI component is displayed *outside* the VncScreen (ie. as a sibling element)
                    loadingUI={<LoadingIndicator />}
                    url={getVncWsUrl({ vmId, location, ...apiParams })}
                    // Options
                    autoConnect
                    scaleViewport
                    // Events
                    onConnect={(rfb) => {
                        console.debug("onConnect", rfb);
                        displayRef.current!.focus();
                        setIsConnected(true);
                    }}
                    onDisconnect={(rfb) => {
                        console.debug("onDisconnect", rfb);
                        setIsConnected(false);
                    }}
                    onSecurityFailure={(rfb) => {
                        console.debug("onSecurityFailure", rfb);
                        // TODO: Display errors
                    }}
                    onCredentialsRequired={(rfb) => {
                        console.debug("onCredentialsRequired", rfb);
                        rfb!.sendCredentials({ password: getVncPassword(vmId) });
                    }}
                />
            </div>
        </div>
    );
}

function LoadingIndicator() {
    return <div className="bg-gray-2 text-muted p-2 rounded m-2 absolute left-0 top-0">Connecting...</div>;
}

export default Vnc;
