import C from "./Toolbar.module.css";

import type { ReactNode } from "react";
import { MaskIcon } from "./icon/MaskIcon.tsx";

export function ToolbarText({ children, icon }: { children: ReactNode; icon?: string }) {
    return (
        <div className={C.Text}>
            {!!icon && <MaskIcon className={`${icon} mr-1`} />}
            {children}
        </div>
    );
}
