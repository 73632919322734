import { createLazyFileRoute } from "@tanstack/react-router";
import Vnc from "../modules/compute/Vnc.tsx";

export const Route = createLazyFileRoute("/console/$location/$vmId")({
    component: Component,
});

function Component() {
    const { vmId, location } = Route.useParams();
    return <Vnc vmId={vmId} location={location} />;
}
